import styles from "./styles.module.scss";

export const Home = () => {
  return (
    <div className={styles.Home}>
      <div className={styles.titleContent}>
        <div className={styles.title}>Hey, I’m Graham</div>
        <div className={styles.subtitle}>
          I’ve always been fascinated with businesses. As a kid, I’d pick
          berries and sell them door to door. In high school, I started a junk
          removal business, always looking for a way to avoid a real job. Come
          college, I started coding and built my first company - it failed flat.
          Now, I’m still on the run, trying to avoid a real job, and building a
          startup full time. It can be painful, but it’s quite the thrill.
        </div>
      </div>
    </div>
  );
};
