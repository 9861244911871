import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import styles from "./styles.module.scss";

export const WritingPage = () => {
  const { file } = useParams<{ file: string }>(); // Capture the article file name from the URL
  const [content, setContent] = useState<string>("");
  const navigate = useNavigate(); // Hook to navigate back

  useEffect(() => {
    const loadArticle = async () => {
      if (file) {
        try {
          const res = await fetch(`/content/${file}`); // Fetch the article from the public/content folder
          const text = await res.text();
          setContent(text);
        } catch (err) {
          console.error("Error loading article:", err);
        }
      }
    };
    loadArticle();
  }, [file]);

  return (
    <div className={styles.WritingPage}>
      {/* Back Arrow to go to Writings */}
      <div className={styles.backButton} onClick={() => navigate("/writings")}>
        ← back to everything else
      </div>
      <div className={styles.blogContentWrapper}>
        {content ? (
          <div className={styles.blogContent} dangerouslySetInnerHTML={{ __html: content }} />
        ) : (
          <p>Loading article...</p>
        )}
      </div>
    </div>
  );
};