import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import styles from "./styles.module.scss"; // Make sure to have your styles in this file

export const Header = () => {
  const location = useLocation();
  const currentPathName = location.pathname;

  const headerItems = [
    {
      name: "Home",
      link: "/",
      testId: "home-tab",
    },
    {
      name: "Writings",
      link: "/writings",
      testId: "writings-tab",
    },
  ];

  return (
    <div className={styles.Header} data-testid="header">
      <div className={styles.HeaderLeft}>
        {/* Logo linked to Home */}
        <Link to="/">
          <img
            src="/logo.png"
            alt="Logo"
            className={styles.logo}
            data-testid="logo"
          />
        </Link>
      </div>

      <div className={styles.HeaderCenter}>
        <div className={styles.headerItems}>
          {headerItems.map((item) => (
            <Link
              className={classNames(styles.headerItem, {
                [styles.headerItemActive]: currentPathName === item.link,
              })}
              data-testid={item.testId}
              to={item.link}
              key={item.link}
            >
              <h3>{item.name}</h3>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};