import { useNavigate } from "react-router-dom";
import { WritingContentMap } from "./WritingContentMap";
import styles from "./styles.module.scss";

type Writing = {
  title: string;
  description: string;
  file: string;
};

export const Writings = () => {
  const navigate = useNavigate();

  const handleArticleClick = (file: string) => {
    navigate(`/writings/${file}`); // Navigate to the dynamic article page based on file name
  };

  return (
    <div className={styles.Writings}>
      <div className={styles.titleContent}>
        <div className={styles.title}>I Like to Write</div>
        <div className={styles.subtitle}>
        Whatever I write - try not to take it too seriously. Also, forgive the spelling. I'm not the biggest fan of proofreading. If you like what you see, let me know, it's good for the ego and we'd probably be friends.
        </div>
      </div>

      <div className={styles.grid}>
        {WritingContentMap.map((writing: Writing, index: number) => (
          <div
            key={index}
            className={styles.gridItem}
            onClick={() => handleArticleClick(writing.file)}
          >
            <h3>{writing.title}</h3>
            <p>{writing.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};