import styles from "./styles/palette.module.scss";

export const useDesign = () => {
  const defaultDesign = {
    backgroundColor: "#fbf9f3",
    cardColor: styles.cardColor,
    textColor: styles.textColor,
    buttonColor: styles.buttonColor,
    subtextColor: styles.subtextColor,
    subBackgroundColor: styles.subBackgroundColor,
    buttonTextColor: styles.buttonTextColor,
    errorColor: styles.errorColor,
    textFont: "Montserrat", // Keep the font definition here or move it to a typography module if needed
  };

  return defaultDesign;
};