import { Provider } from "react-redux";
// import { store } from "src/state/store";
import { Router } from "./Router/Router";
import { ThemeProvider } from "./themeProvider";

export const App = () => {
  return (
    // <Provider store={store}>
    <ThemeProvider>
      <Router />
    </ThemeProvider>
    // </Provider>
  );
};
