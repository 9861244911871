import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Header } from "../components/Header/Header"
import { Home } from "../pages/Home/Home"
import { Footer } from "../components/Footer/Footer";
import styles from "./styles.module.scss";
import { Writings } from "../pages/Writings/Writings";
import { WritingPage } from "../pages/Writings/WritingPage/WritingPage";


export const Router = () => {
  return (
    <BrowserRouter>
      <div className={styles.Router}>
        <Header />
        <div className={styles.content}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/writings" element={<Writings />} />
            <Route path="/writings/:file" element={<WritingPage />} />
          </Routes>
        </div>
        {/* <Footer /> */}
      </div>
    </BrowserRouter>
  )
}